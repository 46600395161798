import React, { useState, useRef, useEffect } from 'react';
import { soundMessages } from '../sound/SoundData.js';
import RandomSoundPlayer from '../sound/RandomSoundPlayer.js';
import './chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Avatar from '../../components/avatar/Avatar.js';
import Title from '../../components/title/Title.js';

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const lastMessageRef = useRef(null);
  const soundPlayerRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const addMessage = (newMessage) => {
    return new Promise(resolve => {
      setMessages(prevMessages => [...prevMessages, newMessage]);
      resolve();
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputValue.trim() !== '') {
      await addMessage({ sender: 'Tú', message: inputValue });
      setInputValue('');
      setIsTyping(true);

      setTimeout(async () => {
        setIsTyping(false);
        const sound = soundPlayerRef.current.playRandomSound();
        await addMessage({ sender: 'Xokchat', message: soundMessages.find(s => s.sound === sound).message });
      }, 1000);
    }
  };

  return (
    <div className="main-container">
      <Title />
      <Avatar /> 
      <RandomSoundPlayer ref={soundPlayerRef} soundMessages={soundMessages}/>
      <div className="rounded-container">
        <div className="message-container">
          {messages.map((message, index) => (
            <div
              key={index}
              ref={index === messages.length - 1 ? lastMessageRef : null}
              className={`message ${message.sender === 'Tú' ? 'message-justify-end' : ''}`}
            >
              <div className={`message-text ${message.sender === 'Tú' ? 'message-text-you' : 'message-text-other'}`}>
                {message.message}
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          className="chat-input"
          id="chat-input"
          placeholder="Escibe un mensaje"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputChange}
        />
        <button type="submit" className="submit-button">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </form>
    </div>
);

  
};

export default ChatBox;
