import React from 'react';

export default class RandomSoundPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.currentAudio = null;
    this.playedSounds = [];
  }

  playRandomSound = () => {
    const { soundMessages } = this.props;

    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
    }

    if (this.playedSounds.length === soundMessages.length) {
      this.playedSounds = [];
    }

    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * soundMessages.length);
    } while (this.playedSounds.includes(randomIndex));

    this.playedSounds.push(randomIndex);

    this.currentAudio = new Audio(soundMessages[randomIndex].sound);
    this.currentAudio.play();

    return soundMessages[randomIndex].sound;
  };

  render() {
    return (
      <div onClick={this.playRandomSound}>
        {this.props.children}
      </div>
    );
  }
}