import React from 'react';
import './title.css';

const Title = () => {
  return (
    <h1 className="title">Xokchat</h1>
  );
}

export default Title;
