// Messages Sounds
import boca from '../../assets/sounds/xokas/boca.mp3';
import calla1 from '../../assets/sounds/xokas/calla1.mp3';
import calla2 from '../../assets/sounds/xokas/calla2.mp3';
import calla3 from '../../assets/sounds/xokas/calla3.mp3';
import que1 from '../../assets/sounds/xokas/que1.mp3';
import que2 from '../../assets/sounds/xokas/que2.mp3';
import que3 from '../../assets/sounds/xokas/que3.mp3';
import que4 from '../../assets/sounds/xokas/que4.mp3';
import que5 from '../../assets/sounds/xokas/que5.mp3';
import que6 from '../../assets/sounds/xokas/que6.mp3';
import soy1 from '../../assets/sounds/xokas/soy1.mp3';
import imbecil from '../../assets/sounds/xokas/imbecil.mp3';
import burro from '../../assets/sounds/xokas/burro.mp3';

// Avatar sounds
import susto from '../../assets/sounds/avatar/susto.mp3';
import juego from '../../assets/sounds/avatar/juego.mp3';
import xokas1 from '../../assets/sounds/avatar/xokas1.mp3';
import xokas2 from '../../assets/sounds/avatar/xokas2.mp3';
import gordito from '../../assets/sounds/avatar/gordito.mp3';
import saludos from '../../assets/sounds/avatar/saludos.mp3';

export const soundMessages = [
  { sound: boca, message: 'Que te calles la boca de una vez 🤫' },
  { sound: calla1, message: '¡¡CÁLLATE!! 🤐' },
  { sound: calla2, message: 'Caaalla la boca 🙄' },
  { sound: calla3, message: '¡Haznos un favor a todos y cállate! 😡' },
  { sound: que1, message: '¿Qué? 🤔' },
  { sound: que2, message: '¿¡Qué quieres que haga!? 😖' },
  { sound: que3, message: '¿Qué miras? 👀' },
  { sound: que4, message: '¿¡Qué dices!? 😠' },
  { sound: que5, message: '¿Qué pasa? 😡' },
  { sound: que6, message: '¡Que te meto! 😡' },
  { sound: soy1, message: '¡Soy yo literal 😯!' },
  { sound: imbecil, message: 'Eres imbécil, ya está 🙃' },
  { sound: burro, message: '¡Tráeme un burro a mi casa hijo de la gran perra! 🌯' },
];

export const avatarSounds = [
  { sound: xokas1, message: '¡Soy el Xokas 🙌!' },
  { sound: xokas2, message: 'Sí, soy yo, el Xokas 👌!' },
  { sound: susto, message: 'Que te calles la boca de una vez 🤫' },
  { sound: juego, message: 'Esto no es un juego, nada es un juego 🎮' },
  { sound: gordito, message: '¡Que gordito! 🐷' },
  { sound: saludos, message: 'No mando saludos! ❌🖖' },
];

