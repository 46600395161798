import React from 'react';
import xokasImage from '../../assets/img/xokas.jpg';
import { avatarSounds } from '../sound/SoundData.js';
import './avatar.css';

const Avatar = ({ style }) => {
  let currentAudio = null;

  const playRandomAvatarSound = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    const randomIndex = Math.floor(Math.random() * avatarSounds.length);
    currentAudio = new Audio(avatarSounds[randomIndex].sound);
    currentAudio.play();
  };

  return (
    <div 
      className="avatar" 
      style={{
        backgroundImage: `url(${xokasImage})`,
        ...style 
      }}
      onClick={playRandomAvatarSound}
    >
    </div>
  );
};

export default Avatar;
